import React from 'react';
import PropTypes from 'prop-types';
import { debugPrint } from '../../utilities/Utilities';


class EmergencyIcons extends React.Component {
    constructor(props) {
        super(props);

        this.state = {

        };

    }
    render() {
        return (
            <div onClick={this.props.callNumber} style={{
                display: "flex",
                justifyItems: "center",
                alignItems: "center",
                flexDirection: "column"
            }}>
                <div style={{
                    background: !this.props.resolved ? "rgba(238, 65, 57, 0.2)" : "rgba(0, 234, 182, 0.2)",
                    height: this.props.height,
                    width: this.props.width,
                    borderRadius: "50px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center"
                }} >

                    <img src={this.props.icon} alt="icon" />
                </div >
                <div style={{
                    fontSize: "12px",
                    color: "Black",
                    fontFamily: "lato",
                    marginTop: 10
                }}>
                    {this.props.title}
                </div>
            </div >
        );
    }
}

EmergencyIcons.propTypes = {
    icon: PropTypes.string,
    title: PropTypes.string,
    resolved: PropTypes.bool,
    height: PropTypes.string,
    width: PropTypes.string,
    callNumber: PropTypes.func

}

EmergencyIcons.defaultProps = {
    icon: "",
    title: "Ambulance",
    resolved: false,
    height: "60px",
    width: "60px",
    callNumber: function () {
        debugPrint("Call Activated")
    }

};

export default EmergencyIcons;
