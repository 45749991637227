import React from "react";
import firestoreDB from "../../utilities/Firebase";
import { debugPrint } from "../../utilities/Utilities";
import { collection, doc, getDoc, onSnapshot, query } from "firebase/firestore";

// const emergencyCollection = collection(firestoreDB, "emergency");

class FirebasePage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      emergencyType: 0,
      victimLat: 0,
      victimLong: 0,
      message: [],
    };

    this.getEmergencyInfo = this.getEmergencyInfo.bind(this);
  }

  componentDidMount() {
    this.getEmergencyInfo();
  }

  async getEmergencyInfo() {
    // //Realtime
    // const unsub = onSnapshot(doc(firestoreDB, "emergency", "23_1647356541309_A9R6hzZR", "messages", "E1fb8teCnoZoWHnu2zQc"), (doc) => {
    //  debugPrint("Current data: ", doc.data());
    //  this.setState({message:doc.data().message})
    // });

    // const querySnapshot = await getDocs(collection(firestoreDB, "emergency","23_1647356541309_A9R6hzZR", "messages"));
    // //["message1", "message2", "message3"]
    // var allMessages = [];
    // querySnapshot.forEach((doc) => {
    // debugPrint(doc.id, " => ", doc.data().message);
    //     allMessages.push(doc.data().message);
    // });

    const emergencyInfo = doc(
      firestoreDB,
      "emergency",
      "23_1647357154262_qOJMNA2r",
      "emergency_info",
      "host"
    );
    const documentSnapshot = await getDoc(emergencyInfo);
    if (documentSnapshot.exists()) {
      debugPrint("Document data:", documentSnapshot.data());
    } else {
      // doc.data() will be undefined in this case
      debugPrint("No such document!");
    }

    const locationInfo = doc(
      firestoreDB,
      "emergency",
      "23_1647357154262_qOJMNA2r",
      "location",
      "23"
    );
    const locationSnapshot = await getDoc(locationInfo);
    if (locationSnapshot.exists()) {
      debugPrint("Document data:", locationSnapshot.data());
    } else {
      // doc.data() will be undefined in this case
      debugPrint("No such document!");
    }

    this.setState({
      emergencyType: documentSnapshot.data().emergency_type,
      victimLat: locationSnapshot.data().lat,
      victimLong: locationSnapshot.data().long,
    });

    const q = query(collection(firestoreDB, "emergency"));
    const unsubscribe = onSnapshot(q, querySnapshot => {
      const allMessages = [];
      querySnapshot.forEach(doc => {
        debugPrint(doc.data().message);
        allMessages.push(doc.data().message);
      });
      debugPrint("Received set news of messages", allMessages.join(", "));
      debugPrint(unsubscribe);
      this.setState({ message: allMessages });
    });
  }

  render() {
    return (
      <div>
        <div>Emergency Type: {this.state.emergencyType}</div>
        <div>Latitude: {this.state.victimLat}</div>
        <div>Longitude: {this.state.victimLong}</div>
        <div>
          {this.state.message.map((message, i) => (
            <div>
              message{i}:{message}
            </div>
          ))}
        </div>
      </div>
    );
  }
}

FirebasePage.propTypes = {};

FirebasePage.defaultProps = {};

export default FirebasePage;
