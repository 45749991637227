import React from "react";
import "./HomePage.css";
import { Grid } from "@material-ui/core";
import GenericButton from "../widgets/GenericButton";
import IconTextInput from "../widgets/IconTextInput";
import threshold from "../../configs/UIThreshold";
import MessageDialog from "../dialogs/MessageDialog";
import Logo from "../../img/icon/siren_logo.png";
import APIResult from "../../configs/APIResult";
import QueryingIndicator from "../widgets/QueryingIndicator";
import { login } from "../../utilities/requests";

class LoginPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      mobileMode: false,
      showAlertDialog: false,
      alertDialogTitle: "Message Dialog Title",
      alertDialogMessage: "Message Dialog Message",
      alertDialogAction: null,
      userEmail: "",
      password: "",
      jwt: "",
      userID: "",
    };

    this.updateState = this.updateState.bind(this);

    this.enableAlertDialog = this.enableAlertDialog.bind(this);

    this.disableAlertDialog = this.disableAlertDialog.bind(this);

    this.updateUserEmail = this.updateUserEmail.bind(this);

    this.updatePassword = this.updatePassword.bind(this);

    this.loginToDashboard = this.loginToDashboard.bind(this);
    this.enterDashboard = this.enterDashboard.bind(this);
  }

  handleResize = e => {
    const windowSize = window.innerWidth;
    this.updateState({
      mobileMode: windowSize < threshold.mobile / 0.75,
      currentWidth: windowSize,
    });
  };

  enableAlertDialog(title, message, action = null) {
    this.updateState({
      alertDialogTitle: title,
      alertDialogMessage: message,
      showAlertDialog: true,
      alertDialogAction: action,
    });
  }

  disableAlertDialog() {
    this.updateState({ showAlertDialog: false });
  }

  updateState(state) {
    if (this._isMounted) {
      this.setState(state);
    }
  }

  componentDidMount() {
    this._isMounted = true;
    const windowSize = window.innerWidth;
    this.updateState({
      mobileMode: windowSize < threshold.mobile / 0.75,
      currentWidth: windowSize,
    });
    window.addEventListener("resize", this.handleResize);
  }

  updateUserEmail(event) {
    this.updateState({ userEmail: event.target.value });
  }

  updatePassword(event) {
    this.updateState({ password: event.target.value });
  }

  async loginToDashboard() {
    var loginResult = await login(this.state.userEmail, this.state.password);

    if (loginResult.status === APIResult.SUCCESS) {
      localStorage.setItem("jwt", loginResult.jwt);
      localStorage.setItem("id", JSON.stringify(loginResult.id));
      this.setState({ loading: true });
      setTimeout(() => {
        this.enterDashboard();
      }, 1000);
    } else if (loginResult.status === APIResult.FAILED) {
      this.enableAlertDialog(
        "",
        "There seems to be an error, please fill in your credentials again"
      );
      this.setState({ loading: false });
    } else if (loginResult.status === APIResult.TIME_OUT) {
      this.enableAlertDialog("", "Session Timed Out. Please try again Later");
      this.setState({ loading: false });
    }
  }

  enterDashboard() {
    this.props.history.push({
      pathname: "./dashboard",
    });
  }

  render() {
    return (
      <div>
        <div>
          <div
            className=""
            style={{
              minHeight: "100vh",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              background: "black",
            }}>
            {this.state.loading ? (
              <div className="loading-bg">
                <div
                  style={{
                    display: "flex",
                    height: "100vh",
                    width: "100vw",
                    justifyContent: "space-around",
                    alignContent: "center",
                    overflow: "hidden",
                  }}>
                  <QueryingIndicator />
                </div>
                :
              </div>
            ) : null}

            <Grid
              style={{
                zIndex: 10,
                position: "fixed",
                width: "100%",
                height: "100%",
                display: "flex",
                flexFlow: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
              container
              spacing={0}
              direction="column">
              <Grid
                item
                style={{
                  justifyContent: "center",
                  alignItems: "center",
                  display: "flex",
                  flex: "1 1 auto",
                }}>
                <img
                  src={Logo}
                  alt="logo"
                  style={{
                    minWidth: 100,
                    width: "50%",
                    maxWidth: 300,
                    minHeight: 50,
                    height: "50%",
                    maxHeight: 150,
                    objectFit: "scale-down",
                  }}
                />
              </Grid>
              <div
                position="relative"
                style={{
                  padding: 28,
                  backgroundColor: "white",
                  borderTopLeftRadius: "32px",
                  borderTopRightRadius: "32px",
                  maxWidth: "600px",
                  flex: "0 1 auto",
                  overflow: "auto",
                  display: "flex",
                  flexDirection: "column",
                }}>
                <div
                  style={{
                    fontFamily: "Lato",
                    fontWeight: "bold",
                    fontSize: "25px",
                    color: "Black",
                    textAlign: "left",
                    paddingLeft: 10,
                    paddingRight: 10,
                  }}>
                  Dashboard Sign In
                </div>

                <Grid container alignItems="flex-end">
                  <IconTextInput
                    id="username"
                    label="ID*"
                    onChange={this.updateUserEmail}
                  />
                  <IconTextInput
                    id="password"
                    label="Password*"
                    type="password"
                    onChange={this.updatePassword}
                  />
                  <div
                    style={{
                      fontFamily: "Lato",
                      fontWeight: "bold",
                      fontSize: "25px",
                      color: "Black",
                      textAlign: "center",
                      paddingLeft: 10,
                      paddingRight: 10,
                    }}></div>
                </Grid>

                <Grid
                  container
                  style={{
                    alignSelf: "baseline",
                    paddingTop: 72,
                    justify: "flex-end",
                    alignContent: "center",
                  }}>
                  <GenericButton
                    label="Sign In"
                    onClick={this.loginToDashboard}
                    fullWidth={true}
                    color="#C4C4C4"
                    borderRadius="10px"
                    enable={
                      this.state.password.length === 0 ||
                      this.state.userEmail.length === 0
                        ? false
                        : true
                    }
                  />
                  <div
                    style={{
                      display: "flex",
                      boxSizing: "border-box",
                      justifyContent: "space-around",
                      textAlign: "center",
                      fontFamily: "Lato",
                      color: "rgba(0,0,0,0.5)",
                      paddingTop: 30,
                      paddingBottom: 15,
                      width: "100%",
                    }}>
                    <div>© Siren</div>
                    <div>
                      <a href="https://www.siren-app.com/privacypolicy">
                        Privacy & terms
                      </a>
                    </div>
                  </div>
                </Grid>
              </div>
            </Grid>
          </div>
        </div>
        <MessageDialog
          open={this.state.showAlertDialog}
          onProceed={
            this.state.alertDialogAction == null
              ? this.disableAlertDialog
              : this.state.alertDialogAction
          }
          onCancel={this.disableAlertDialog}
          showCancel={false}
          title={this.state.alertDialogTitle}
          message={this.state.alertDialogMessage}
        />
      </div>
    );
  }
}

export default LoginPage;
