export const DEFAULT_EMERGENCY_CALLS_COUNT = {
    "m-0": 0,
    "m-1": 0,
    "m-2": 0,
    "m-3": 0,
    "m-4": 0,
    "m-5": 0,
    "total": 0
};

export const DEFAULT_EMERGENCY_VICTIM_INFO = {
    name: "-",
    country_code: "60",
    phone: "-",
    email: "-",
    sign_up_timestamp: 1577840400,
    profile_picture: "",
    channel_id: "",
    user_id: 0,
    longitude: 0,
    latitude: 0
}

export const DEFAULT_FEED_INFO = {
    feed_datetime: 1649672769,
    feed_title: "",
    feed_id: 0,
    feed_content: "",
    feed_cover_image: "",
    feed_published: 0,
    feed_code: 0
}


