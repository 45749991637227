const APIResult = {
    TIME_OUT: -1,
    FAILED: 0,
    SUCCESS: 1,

    // GET HOMEPAGE
    INVALID_SESSION: 1002,

    




}


export default APIResult;