const APIPaths = {
    apiEndPoint: 'https://api.siren-app.com/',
    login: "admin/login",
    homepageInfo: "admin/get_homepage_info",
    emergencyPageInfo: "admin/get_emergency_info",
    feedPageInfo: "admin/get_feeds_info",
    deleteFeed: "admin/delete_feed",
    addEditFeedInfo: "admin/add_edit_feed",
    setServicesNumber: "admin/set_services_number",
    checkNewEmergency: "admin/check_new_emergency"
};

export default APIPaths;