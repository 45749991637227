import React from 'react';
import PropTypes from 'prop-types';
import CancelIcon from "../../img/icon/cancelIcon.svg"
import { Divider } from '@mui/material';


class NotificationAlert extends React.Component {
    constructor(props) {
        super(props);

        this.state = {

        };

    }
    render() {
        return (
            <div>
                {this.props.showDivider ?
                    <div style={{ marginBottom: " 10px" }}>
                        <Divider />
                    </div> : null
                }
                <div style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    height: "auto",
                    width: "250px",
                    fontFamily: "lato",

                    gap: 10,
                }}>
                    <div style={{
                        position: "relative",
                        width: "36px",
                        height: "36px",
                        overflow: "hidden",
                        borderRadius: "50%",
                    }}>
                        <img
                            alt=""
                            onError={(e) => {
                                e.target.onerror = null;
                                e.target.src = CancelIcon;
                            }}
                            src={this.props.icon}
                            style={{
                                width: " 100%",
                                height: "100%",
                                objectFit: "cover",
                            }} />
                    </div>
                    <div style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                    }}>
                        <div style={{
                            fontSize: "14px",
                            fontWeight: "bold"
                        }}>
                            {this.props.title}
                        </div>
                        <div
                            style={{
                                width: "200px",
                                fontSize: "12px",
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                            }}>
                            {this.props.name} {this.props.message}
                        </div>
                    </div>
                </div>
                <div>

                </div>

            </div >
        );
    }
}

NotificationAlert.propTypes = {
    icon: PropTypes.string,
    title: PropTypes.string,
    message: PropTypes.string,
    name: PropTypes.string,
    showDivider: PropTypes.bool
}

NotificationAlert.defaultProps = {
    icon: CancelIcon,
    title: "Medical Emergency",
    message: "has initiated a medication booster on Alvin",
    name: "JungKook",
    showDivider: true


};

export default NotificationAlert;
