const colors = {
    
    primary: "#1D2225",
    primarySlightLight: "#1D2225A0",
    primaryBright: "#FFDACC",
    primaryLight: "#1D222540",
    primarySuperLight: "#1D222530",
    primaryUltraLight: "#1D222520",
    primaryShadow: "#1D222580",
    primaryBackdrop: "#7A3713",
    primaryDark: "#C43B00",
    primaryDarkShadow: "#8c000080",
    primaryDeepDark: "#8c0000",
    primarySoftDark: "#5A301E60",
    secondary: "#456A7C",
    grey: "#00000080",
    darkGrey: "#000000B0",
    brightGrey: "#FFFFFFB0",
    backgroundGrey: "#ECF0F3",
    softBlack: "#302B2A",
    gold: "#FFA500",
    bottomNavigationBarBackground: "#e85200",

    alertRed: "#FF0000",

    // Color for stock status
    stockLive: "#66BB6A",
    stockCleared: "#FF9800",
    stockLiveLimitedTime: "#69F542",
    stockExpired: "#9E6629",
    stockDelisted: "#F44336",

    // Color for order status
    orderUnpaid: "#7F338C",
    orderPaid: "#A46828",
    orderDelivering: "#366E8D",
    orderCompleted: "#2F7456",
    orderFailedToDeliver: "#F44336",

    // Color for standard tone
    standardBlue: "#578FDC",
    standardGreen: "#64C8BD",
    standardPurple: "#AD92FD",
    standardOrange: "#FFBA4C",
    standardGrey: "#384364"
  };


export default colors