import React from 'react';
import PropTypes from 'prop-types';
import ProfilePicture from "../../img/icon/ProfilePicture.svg"
import {getInitials, showDashedValue} from '../../utilities/Utilities';
import UnknownIcon from "../../img/icon/UnknownIcon.svg";
import MedicalIcon from "../../img/icon/medical_icon.svg";
import FireIcon from "../../img/icon/fire_icon.svg";
import CrimeIcon from "../../img/icon/crime_icon.svg";
import AutoIcon from "../../img/icon/auto_icon.svg";

class EmergencyCallCard extends React.Component {

    constructor(props) {
        super(props);

        this.state = {};
    }

    getIcon(type)
    {
        switch (type) {
            case 0:
                return MedicalIcon;
            case 1:
                return AutoIcon;
            case 2:
                return FireIcon;
            case 3:
                return CrimeIcon;
            default:
                return UnknownIcon;
        }
    }

    render() {
        return (
            <div
                style={{
                cursor: "pointer",
                userSelect: "none"
            }}>
                <div
                    name="card-container"
                    style={{
                    height: "auto",
                    width: "auto",
                    maxWidth: "400px",
                    borderRadius: "10px",
                    padding: "1em",
                    backgroundColor: "white"
                }}>
                    <div
                        style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between"
                    }}>
                        <div>
                            {this.props.emergencyInfo.profile_picture === ""
                                ? <div
                                        style={{
                                        objectFit: "cover",
                                        borderRadius: "50%",
                                        width: "65px",
                                        height: "65px",
                                        background: "black",
                                        display: "flex",
                                        color: "white",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        fontSize: 20,
                                        fontWeight: 700
                                    }}>{getInitials(this.props.emergencyInfo.name)}</div>
                                : <img
                                    src={this.props.emergencyInfo.profile_picture}
                                    alt="profile"
                                    style={{
                                    objectFit: "cover",
                                    borderRadius: "50%",
                                    width: "65px",
                                    height: "65px"
                                }}/>}
                        </div>
                        <div
                            style={{
                            fontFamily: "Lato",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            width: "-webkit-fill-available",
                            marginLeft: "1em"
                        }}>
                            <div
                                style={{
                                color: "black",
                                fontFamily: "Lato",
                                fontWeight: 'bold',
                                fontSize: "20px",
                                paddingBottom: "10px",
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                textOverflow: "ellipsis"
                            }}>
                                {this.props.emergencyInfo.name}
                            </div>
                            <div
                                style={{
                                color: "rgba(0, 0, 0, 0.75)",
                                fontSize: "12px",
                                fontWeight: 'bold',
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                textOverflow: "ellipsis"
                            }}>
                                {showDashedValue(parseFloat(this.props.emergencyInfo.latitude).toFixed(5))}, {showDashedValue(parseFloat(this.props.emergencyInfo.longitude).toFixed(5))}
                            </div>
                            <div
                                style={{
                                color: "rgba(0, 0, 0, 0.5)",
                                fontSize: "12px",
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                textOverflow: "ellipsis"
                            }}>
                                +{this.props.emergencyInfo.country_code} {this.props.emergencyInfo.phone}
                            </div>
                        </div>

                        <div style={{}}>
                            <img
                                src={this.getIcon(this.props.emergencyInfo.emergencyType)}
                                alt="icon"
                                style={{
                                width: "30px",
                                height: "30px",
                                borderRadius: "50%"
                            }}/>
                        </div>
                    </div>
                </div >
            </div >
        );
    }
}

EmergencyCallCard.propTypes = {
    name: PropTypes.string,
    location: PropTypes.string,
    phoneNumber: PropTypes.string,
    profilePicture: PropTypes.string,
    icon: PropTypes.string,
    countryCode: PropTypes.string

};

EmergencyCallCard.defaultProps = {
    name: "Name",
    location: "Location Name",
    phoneNumber: "012-3456789",
    profilePicture: ProfilePicture,
    icon: MedicalIcon,
    countryCode: ''

};
export default EmergencyCallCard;