import React from 'react';
import PropTypes from 'prop-types';
import CancelIcon from "../../img/icon/cancelIcon.svg"
import EmergencyIcons from './EmergencyIcons';
import CarTowIcon from "../../img/icon/car_towing.svg"
import PoliceIcon from "../../img/icon/999.svg"
import LocationIcon from "../../img/icon/LocationIcon.svg"
import AmbulanceIcon from "../../img/icon/ambulance.svg"
import { epochToDate, getEmergencyIcon, getInitials } from '../../utilities/Utilities';
import { showDashedValue } from '../../utilities/Utilities';

class EmergencyCardOverview extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            inEmergency: false,
            inFeeds: false,
            inSettings: false
        };

        this.onProceed = this
            .onProceed
            .bind(this);
        this.getEmergencyTypeTitle = this
            .getEmergencyTypeTitle
            .bind(this)

    }
    componentDidMount() { }

    onProceed() {
        this
            .props
            .onProceed();
    }

    getEmergencyTypeTitle(type=-1) {
        switch (type) {
            default:
                return null;
            case - 1:
                return ("Unknown");
            case 0:
                return ("Medical");
            case 1:
                return ("Auto");
            case 2:
                return ("Ambulance");
            case 3:
                return ("Crime");
        }
    }

    render() {
        return (
            <div
                className="overview-container"
                style={{
                    height: "550px",
                    width: "360px",
                    background: "white",
                    borderRadius: "16px",
                    padding: 10,
                    fontFamily: "lato"
                }}>

                <div
                    className="top-flex"
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "center",
                        cursor: "pointer"
                    }}>
                    <img onClick={this.onProceed} src={CancelIcon} alt="icon" />

                    <div
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            background: "rgba(238, 65, 57, 0.2)",
                            borderRadius: "16px",
                            padding: 5,
                            justifyContent: "center",
                            alignItems: "center"
                        }}>

                        <img
                            src={getEmergencyIcon(this.props.info.emergencyType)}
                            style={{
                                height: "20px",
                                width: "20px"
                            }}
                            alt="icon" />

                        <div
                            style={{
                                fontFamily: "lato",
                                fontWeight: "700",
                                color: "#EE4139",
                                fontSize: "10px",
                                marginLeft: 10,
                                marginRight: 10
                            }}>
                            {this.getEmergencyTypeTitle(this.props.info.emergencyType)}

                        </div>
                    </div>

                </div>
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center"
                    }}>
                    <div
                        style={{
                            position: "relative",
                            width: "128px",
                            height: "128px",
                            overflow: "hidden",
                            objectFit: "cover",
                            borderRadius: "50%"
                        }}>
                        {this.props.info.profile_picture === ""
                            ? <div
                                style={{
                                    width: " 100%",
                                    height: "100%",
                                    objectFit: "cover",
                                    borderRight: "50%",
                                    background: "black",
                                    display: "flex",
                                    color: "white",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    fontSize: 20,
                                    fontWeight: 700
                                }}>{getInitials(this.props.info.name)}</div>
                            : <img
                                src={this.props.info.profile_picture}
                                alt="profile"
                                style={{
                                    width: " 100%",
                                    height: "100%",
                                    objectFit: "cover",
                                    borderRight: "50%"
                                }} />}
                    </div>
                    <div
                        style={{
                            fontWeight: "bold",
                            fontSize: "24px",
                            color: "black"
                        }}>
                        {this.props.info.name}
                    </div>

                    <div
                        style={{
                            fontWeight: 600,
                            color: "#00000050",
                            fontSize: "12px",
                            marginBottom: 10,
                            marginTop: 10
                        }}>
                        Member since {epochToDate(this.props.info.sign_up_timestamp)}
                    </div>

                    <a
                        href={`mailTo:${this.props.info.email}`}
                        style={{
                            fontWeight: 600,
                            color: "#0000EE",
                            fontSize: "16px",
                            textDecoration: "none",
                            lineHeight: " 20px",
                            cursor: "pointer",
                            borderBottom: " 1px solid #0000EE",
                            marginBottom: 10
                        }}>
                        {this.props.info.email}
                    </a>

                    <a
                        href={`Tel:${this.props.info.country_code}${this.props.info.phone}`}
                        style={{
                            fontWeight: 600,
                            color: "#0000EE",
                            fontSize: "16px",
                            textDecoration: "none",
                            borderBottom: " 1px solid #0000EE",
                            cursor: "pointer",
                            marginBottom: 60
                        }}>
                        +{this.props.info.country_code
                        } {this.props.info.phone
                        }

                    </a>

                    <div
                        style={{
                            fontSize: "14px",
                            color: "#000000",
                            fontWeight: 600
                        }}>
                        Send Help?
                    </div>

                    <div
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "center",
                            alignItems: "center",
                            gap: 20,
                            padding: 10,
                            marginBottom: 20,
                            cursor: "pointer"
                        }}>

                        <a
                            href={`Tel:${localStorage.getItem("police")}`}
                            style={{
                                textDecoration: "none"
                            }}><EmergencyIcons title="999" icon={PoliceIcon} /></a>
                        <a
                            href={`Tel:${localStorage.getItem("ambulance")}`}
                            style={{
                                textDecoration: "none"
                            }}><EmergencyIcons title="Ambulance" icon={AmbulanceIcon} /></a>
                        <a
                            href={`Tel:${localStorage.getItem("carTow")}`}
                            style={{
                                textDecoration: "none"
                            }}><EmergencyIcons title="Car Towing" icon={CarTowIcon} /></a>
                    </div>

                    <a
                        href={`https://www.google.com/maps/dir/?api=1&destination=${this.props.info.latitude}%2C${this.props.info.longitude}`}
                        target="_blank"
                        rel="noreferrer"
                        style={{
                            textDecoration: "none"
                        }}>
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                gap: 10,
                                alignItems: "baseline"
                            }}>
                            <EmergencyIcons
                                width="24px"
                                height="24px"
                                title=""
                                icon={LocationIcon}
                                resolved={true} />
                            <div
                                style={{
                                    fontWeight: 600,
                                    color: "rgba(0, 0, 0, 0.75)"
                                }}>
                                {showDashedValue(parseFloat(this.props.info.latitude).toFixed(5))}, {showDashedValue(parseFloat(this.props.info.longitude).toFixed(5))}

                            </div>
                        </div>
                    </a>
                </div>
            </div >
        );
    }
}

EmergencyCardOverview.propTypes = {
    typeTitle: PropTypes.string,
    name: PropTypes.string,
    signUpDate: PropTypes.string,
    email: PropTypes.string,
    phoneNumber: PropTypes.string,
    coords: PropTypes.string,
    onProceed: PropTypes.func,
    latitude: PropTypes.string,
    longitude: PropTypes.string,
    police: PropTypes.string,
    fireBrigade: PropTypes.string,
    carTow: PropTypes.string,
    icon: PropTypes.string
}

EmergencyCardOverview.defaultProps = {
    icon: CarTowIcon,
    typeTitle: "Crime",
    name: "Lee Jung-jae",
    signUpDate: " 2nd March 2022",
    email: "",
    phoneNumber: "",

    latitude: "3.1020323",
    longitude: " 101.7182848",
    onProceed: function () { }
};

export default EmergencyCardOverview;
