import React from "react";
import EmergencyCallCard from "../widgets/EmergencyCallCard";
import Exclamation from "../../img/icon/ExclamationMark.svg";
import GreenExclamation from "../../img/icon/green_exclamation.svg";
import CardFrame from "../widgets/CardFrame";
import { emergencyPageInfo } from "../../utilities/requests";
import APIResult from "../../configs/APIResult";
import QueryingIndicator from "../widgets/QueryingIndicator";
import "./EmergencyPage.css";
import PageNavigation from "../widgets/PageNavigation";
import firestoreDB from "../../utilities/Firebase";
import { doc, onSnapshot } from "firebase/firestore";
import EmergencyCardOverview from "../widgets/EmergencyCardOverview";
import { DEFAULT_EMERGENCY_VICTIM_INFO } from "../../configs/Default";
import { debugPrint } from "../../utilities/Utilities";

const ONGOING = 0;
const RESOLVED = 1;

class EmergencyPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      currentPage: 1,
      dataToOverview: 2,
      showEmergencyCardOverview: false,
      currentEmergencyOverview: DEFAULT_EMERGENCY_VICTIM_INFO,
      loading: true,
      onGoingEmergencies: [],
      resolvedEmergencies: [],
      totalOnGoingPages: 0,
      totalResolvedPages: 0,
      currentResolvedPage: 0,
      banList: [],
    };
    this.getOnGoingEmergencyPageInfo =
      this.getOnGoingEmergencyPageInfo.bind(this);
    this.getResolvedEmergencyPageInfo =
      this.getResolvedEmergencyPageInfo.bind(this);

    this.enterEmergencyOverview = this.enterEmergencyOverview.bind(this);
    this.UpdateData = this.UpdateData.bind(this);
    this.PageNavigation = this.navigatePages.bind(this);
    this.getEmergencyType = this.getEmergencyType.bind(this);

    this.trackFirebaseInfo = this.trackFirebaseInfo.bind(this);
    this.currentPage = 1;
    this.onGoingPages = 0;
    this.cardLimit = 6;
    this.itemPerPage = 6;
  }
  async componentDidMount() {
    this.getOnGoingEmergencyPageInfo();
    this.getResolvedEmergencyPageInfo();
  }

  navigatePages(page) {
    this.currentPage = page;
    this.UpdateData();
  }

  enterEmergencyOverview(emergencyInfo) {
    this.setState({
      showEmergencyCardOverview: !this.state.showEmergencyCardOverview,
      currentEmergencyOverview: emergencyInfo,
    });
  }
  async trackFirebaseInfo(channelID, userID, type = ONGOING) {
    onSnapshot(
      doc(firestoreDB, "emergency", channelID, "location", userID),
      doc => {
        var allEmergency =
          type === ONGOING
            ? this.state.onGoingEmergencies
            : this.state.resolvedEmergencies;
        var updatedEmergency = [];
        allEmergency.forEach(eachEmergency => {
          if (eachEmergency.channel_id === channelID) {
            if (doc.data() !== undefined) {
              eachEmergency.longitude = doc.data().long;
              eachEmergency.latitude = doc.data().lat;
            }
          }
          updatedEmergency.push(eachEmergency);
        });
        if (type === ONGOING) {
          this.setState({ onGoingEmergencies: updatedEmergency });
        } else if (type === RESOLVED) {
          this.setState({ resolvedEmergencies: updatedEmergency });
        }
      }
    );
  }

  async getEmergencyType(channelID, type = ONGOING) {
    onSnapshot(
      doc(firestoreDB, "emergency", channelID, "emergency_info", "host"),
      doc => {
        var allEmergency =
          type === ONGOING
            ? this.state.onGoingEmergencies
            : this.state.resolvedEmergencies;
        var updatedEmergency = [];
        allEmergency.forEach(eachEmergency => {
          if (eachEmergency.channel_id === channelID) {
            if (doc.data() !== undefined) {
              eachEmergency.emergencyType = doc.data().emergency_type;
            }
          }

          updatedEmergency.push(eachEmergency);
        });
        if (type === ONGOING) {
          this.setState({ onGoingEmergencies: updatedEmergency });
        } else if (type === RESOLVED) {
          this.setState({ resolvedEmergencies: updatedEmergency });
        }
      }
    );
  }

  async UpdateData() {
    await emergencyPageInfo(
      localStorage.getItem("id"),
      localStorage.getItem("jwt"),
      0,
      this.cardLimit,
      this.currentPage + 1
    );
  }

  async getOnGoingEmergencyPageInfo() {
    if (
      localStorage.getItem("id") === null &&
      localStorage.getItem("jwt") === null
    ) {
      this.enterDashboardLogin();
    }
    var emergencyPageResult = await emergencyPageInfo(
      localStorage.getItem("id"),
      localStorage.getItem("jwt"),
      1,
      this.cardLimit,
      this.currentPage
    );

    if (emergencyPageResult.status === APIResult.SUCCESS) {
      this.setState(
        {
          totalOnGoingPages: Math.ceil(
            Math.max(emergencyPageResult.total, 1) / this.itemPerPage
          ),
          onGoingEmergencies: emergencyPageResult["emergency"],
        },
        () => {
          emergencyPageResult.emergency.forEach(eachEmergency => {
            this.trackFirebaseInfo(
              eachEmergency.channel_id,
              eachEmergency.user_id.toString(),
              ONGOING
            );
            this.getEmergencyType(eachEmergency.channel_id, ONGOING);
          });
          var greenList = [];
          for (var i = 0; i < this.state.onGoingEmergencies.length; i++) {
            if (this.state.onGoingEmergencies[i].latitude === undefined) {
              this.state.banList.push(this.state.onGoingEmergencies[i]);
            } else {
              greenList.push(this.state.onGoingEmergencies[i]);
            }
            this.setState({ onGoingEmergencies: greenList });
          }
        }
      );

      // debugPrint(this.onGoingPages)
    } else if (emergencyPageResult.status === APIResult.INVALID_SESSION) {
      this.enterDashboardLogin();
    } else {
      debugPrint("Emergency API couldnt work");
    }
  }

  async getResolvedEmergencyPageInfo() {
    this.setState({ loading: true });
    if (
      localStorage.getItem("id") === null &&
      localStorage.getItem("jwt") === null
    ) {
      this.enterDashboardLogin();
    }

    var emergencyPageResult = await emergencyPageInfo(
      localStorage.getItem("id"),
      localStorage.getItem("jwt"),
      0,
      this.cardLimit,
      this.state.currentResolvedPage
    );
    if (emergencyPageResult.status === APIResult.SUCCESS) {
      var greenList = [];
      for (var i = 0; i < emergencyPageResult["emergency"].length; i++) {
        if (
          emergencyPageResult["emergency"][i]["channel_id"].startsWith("TEST")
        ) {
          this.state.banList.push(emergencyPageResult["emergency"][i]);
        } else {
          greenList.push(emergencyPageResult["emergency"][i]);
        }
      }
      this.setState(
        {
          totalResolvedPages: Math.ceil(
            Math.max(emergencyPageResult.total, 1) / this.itemPerPage
          ),
          resolvedEmergencies: greenList,
          // .slice((this.state.currentResolvedPage-1)* this.itemPerPage,this.state.currentResolvedPage*this.itemPerPage)
        },
        () => {
          emergencyPageResult.emergency.forEach(eachEmergency => {
            this.trackFirebaseInfo(
              eachEmergency.channel_id,
              eachEmergency.user_id.toString(),
              RESOLVED
            );

            this.getEmergencyType(eachEmergency.channel_id, RESOLVED);
          });
          for (var i = 0; i < this.state.resolvedEmergencies.length; i++) {
            if (this.state.resolvedEmergencies[i].latitude === undefined) {
              this.state.banList.push(this.state.resolvedEmergencies[i]);
            } else {
              greenList.push(this.state.resolvedEmergencies[i]);
            }
            this.setState({ resolvedEmergencies: greenList });
          }
        }
      );
      this.setState({
        loading: false,
        totalResolvedPages: emergencyPageResult.total,
      });
    } else if (emergencyPageResult.status === APIResult.INVALID_SESSION) {
      this.enterDashboardLogin();
      this.setState({ loading: false });
    } else {
      debugPrint("Emergency API couldnt work");
      this.setState({ loading: false });
    }
  }

  render() {
    const { dataToOverview } = this.state;
    return (
      <div
        className=""
        style={{
          width: "100%",
          height: this.state.loading ? "100vh" : " 100%",
          paddingBottom: "200px",
          marginTop: 16,
        }}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: 10,
          }}>
          {this.state.showEmergencyCardOverview ? (
            <div>
              <div
                style={{
                  position: "relative",
                  width: "100vw",
                  height: "100vh",
                  zIndex: 35,
                }}>
                <div
                  style={{
                    position: "fixed",
                    width: "100vw",
                    height: "100vh",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    top: 0,
                    left: 0,
                  }}
                  className="emergencyCardOverview-layout">
                  <EmergencyCardOverview
                    pageType={dataToOverview}
                    onProceed={this.enterEmergencyOverview}
                    info={this.state.currentEmergencyOverview}
                  />
                </div>
              </div>
            </div>
          ) : null}
          <div className="home-parent">
            <CardFrame title="On-Going" icon={Exclamation}>
              {this.state.onGoingEmergencies.map((emergencyInfo, index) => {
                return (
                  <div
                    key={index}
                    onClick={() => this.enterEmergencyOverview(emergencyInfo)}
                    style={{
                      marginBottom: 10,
                      marginTop: 10,
                    }}>
                    <EmergencyCallCard
                      key={index}
                      id={index}
                      emergencyInfo={emergencyInfo}
                    />
                  </div>
                );
              })}
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "2em",
                }}>
                {this.state.onGoingEmergencies.length !== 0 ? (
                  <PageNavigation
                    onPageChange={this.UpdateData}
                    total={Math.ceil(
                      Math.max(1, this.state.totalOnGoingPages) /
                        this.itemPerPage
                    )}
                  />
                ) : null}
              </div>
            </CardFrame>
            <CardFrame title="Resolved" icon={GreenExclamation}>
              {this.state.resolvedEmergencies.map(
                (emergencyInfo, index, arr) => {
                  return (
                    <div
                      key={index}
                      onClick={() => this.enterEmergencyOverview(emergencyInfo)}
                      style={{
                        marginBottom: 10,
                        marginTop: 10,
                      }}>
                      <EmergencyCallCard
                        key={index}
                        id={index}
                        emergencyInfo={emergencyInfo}
                      />
                    </div>
                  );
                }
              )}

              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "2em",
                }}>
                {this.state.resolvedEmergencies.length !== 0 ? (
                  <PageNavigation
                    currentPage={this.state.currentResolvedPage + 1}
                    total={Math.ceil(
                      Math.max(this.state.totalResolvedPages, 1) /
                        this.itemPerPage
                    )}
                    onPageChange={e => {
                      this.setState(
                        {
                          currentResolvedPage: e - 1,
                        },
                        () => {
                          this.getResolvedEmergencyPageInfo();
                        }
                      );
                    }}
                    //     currentPage={this.state.currentResolvedPage}
                    //     onPageChange={(e) => {

                    //     this.setState({
                    //         currentResolvedPage: e
                    //     },);
                    //     this.getResolvedEmergencyPageInfo()
                    // }}
                    //     total={(Math.ceil(Math.max(1,this.state.totalResolvedPages) / this.itemPerPage))}
                  />
                ) : null}
              </div>
            </CardFrame>
          </div>
        </div>
        {this.state.loading ? <div className="loading-bg" /> : null}
        {this.state.loading ? (
          <div
            id="ppwwwoopp"
            style={{
              position: "fixed",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100vw",
              height: "100vh",
              zIndex: 2000,
              left: 0,
              top: 0,
            }}>
            <QueryingIndicator />
          </div>
        ) : null}
      </div>
    );
  }
}
EmergencyPage.propTypes = {};

EmergencyPage.defaultProps = {};

export default EmergencyPage;
