import React from "react";
import Logo from "../../img/icon/siren_logo.png";

class DefaultPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    return (
      <div
        className="defaultPage-bg"
        style={{
          height: "100vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}>
        <img src={Logo} height="150px" width="400px" alt="" />
      </div>
    );
  }
}
DefaultPage.propTypes = {};

DefaultPage.defaultProps = {};

export default DefaultPage;
