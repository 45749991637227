import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import ConfigUIColors from '../../configs/UIColors';
import { SpinnerCircular } from 'spinners-react';


class QueryingIndicator extends Component {

    render() {
        return this.props.querying
            ? <div
                style={{
                    zIndex: 15,
                    display: "flex",
                    justifyContent: "center",
                    alignContent: "center"
                }}>

                <SpinnerCircular
                    size={50}
                    thickness={100}
                    speed={100}
                    color="#EE4139"
                    secondaryColor="rgba(0, 0, 0, 0.44)" />
            </div>
            : null;
    }
}

const styles = props => ({
    colorPrimary: {
        backgroundColor: ConfigUIColors.primary
    },
    barColorPrimary: {
        backgroundColor: ConfigUIColors.primaryDark
    }
});

QueryingIndicator.propTypes = {
    querying: PropTypes.bool
};

QueryingIndicator.defaultProps = {
    querying: true
};

export default withStyles(styles)(QueryingIndicator);