import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@material-ui/core';
import ConfigUIColors from '../../configs/UIColors';

class GenericButton extends React.Component {
    render() {
        return (
            <Button
                disabled={!this.props.enable}
                onClick={this.props.onClick}
                size={this.props.size}
                variant={"outlined"}
                style={{
                    borderWidth: 2,
                    borderColor: 'transparent',
                    fontFamily: "Lato",
                    fontWeight: "bolder",
                    borderRadius: this.props.borderRadius,
                    fontSize: this.props.fontSize,
                    backgroundColor: this.props.enable
                        ? "#EE4139"
                        : "#C4C4C4",
                    color: this.props.textColor,
                    width: this.props.fullWidth
                        ? '100%'
                        : 'auto',
                    height: this.props.fullHeight
                        ? '100%'
                        : 'auto',
                    padding: this.props.padding,
                    textTransform: 'none',

                }}>
                {this.props.label}
            </Button>
        );
    }
}

GenericButton.propTypes = {
    label: PropTypes.string,
    size: PropTypes.string,
    color: PropTypes.string,
    textColor: PropTypes.string,
    fontSize: PropTypes.number,
    secondary: PropTypes.bool,
    fullWidth: PropTypes.bool,
    fullHeight: PropTypes.bool,
    enable: PropTypes.bool,
    onClick: PropTypes.func,
    borderRadius: PropTypes.string,
    padding: PropTypes.number,
    backgroundColor: PropTypes.string,
    width: PropTypes.string
}

GenericButton.defaultProps = {
    label: 'GenericButton',
    size: 'large',
    textColor: "white",
    color: ConfigUIColors.primary,
    backgroundColor: "#EE4139",
    fontSize: 14,
    secondary: false,
    fullWidth: false,
    fullHeight: false,
    enable: true,
    borderRadius: "10px",
    padding: 16


};

export default GenericButton;
