import React from 'react';
import PropTypes from 'prop-types';
import { Grid, IconButton, InputAdornment, TextField } from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { MuiThemeProvider, createTheme } from "@material-ui/core/styles";
import { debugPrint } from '../../utilities/Utilities';

const theme = createTheme({
    overrides: {
        MuiFilledInput: {
            root: {
                backgroundColor: "transparent",
                "&:hover": {
                    backgroundColor: "transparent",
                    "@media (hover: none)": {
                        backgroundColor: "transparent"
                    }
                },
                "&.Mui-focused": {
                    backgroundColor: "transparent"
                }
            }
        }
    }
});

class IconTextInput extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showPassword: false
        };

    }

    triggerShowPassword(trigger) {
        this.setState({ showPassword: trigger });
    }

    render() {
        return (
            <Grid
                container
                direction="row"
                alignItems="center"
                style={{
                    padding: 6
                }}>

                <Grid item xs={2}>
                    <img
                        alt=""
                        src={this.props.icon}
                        style={{
                            height: "20px"
                        }} />
                </Grid>
                <Grid item xs={12}>
                    <MuiThemeProvider theme={theme}>
                        <TextField
                            className="input-text-container"
                            variant="filled"
                            id={this.props.id}
                            onChange={this.props.onChange}
                            inputRef={this.props.inputRef}
                            fullWidth={true}
                            label={this.props.label}
                            defaultValue={this.props.defaultValue}

                            InputProps={{
                                className: "input-text",
                                disableUnderline: true,
                                startAdornment: this.props.mobile !== ''
                                    ? <InputAdornment position="start">
                                        {this.props.mobile}
                                    </InputAdornment>
                                    : null,
                                endAdornment: (this.props.type === "password"
                                    ? <InputAdornment position="end">
                                        <Grid item xs={2}>
                                            {this.state.showPassword
                                                ? <IconButton onClick={() => this.triggerShowPassword(false)}>
                                                    <Visibility />
                                                </IconButton>
                                                : <IconButton onClick={() => this.triggerShowPassword(true)}>
                                                    <VisibilityOff />
                                                </IconButton>}
                                        </Grid>
                                    </InputAdornment>
                                    : null)
                            }}
                            InputLabelProps={{
                                className: "input-text-label",
                                // shrink: false
                            }}

                            type={this.state.showPassword
                                ? "text"
                                : this.props.type}
                            style={{
                                fontWeight: "bolder"
                            }} />
                    </MuiThemeProvider>
                </Grid>
            </Grid>
        );
    }

}

IconTextInput.propTypes = {
    id: PropTypes.string,
    label: PropTypes.string,
    mobile: PropTypes.string,
    type: PropTypes.string,
    onChange: PropTypes.func,
    defaultValue: PropTypes.string
};

IconTextInput.defaultProps = {
    id: 'icon_text_input',
    label: 'TextButton',
    mobile: '',
    type: 'text',
    onChange: function (event) {
        debugPrint("Value update -> " + event.target.value);
        return event.target.value;
    }
};

export default IconTextInput;