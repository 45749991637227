import AlertIcon from "../../img/icon/AlertIcon.svg"
import MedicalIcon from "../../img/icon/medical_icon.svg"
import PropTypes from 'prop-types';
import React from 'react';

class TotalEmergencyCallsCard extends React.Component {

    constructor(props) {
        super(props);

        this.state = {

        };
    }

    render() {
        return (
            <div style={{}}>
                <div
                    style={{
                        height: "auto",
                        borderRadius: "10px",
                        padding: "15px",
                        backgroundColor: "white",

                    }}>
                    <div id="top-flex-container"
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between"
                        }}>
                        <div
                            style={{
                                fontFamily: "Lato",
                                fontWeight: 'bold',
                                fontSize: "18px",
                                color: 'Black',
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                            }}>

                            <div style={{}}>
                                {this.props.name}
                            </div>

                        </div>
                        <div
                            style={{
                                backgroundColor: "rgba(238, 65, 57, 0.2)",
                                borderRadius: "40px",
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: 'center',
                                alignItems: 'center',
                                width: "auto",
                                padding: "4px",
                                height: "15px"
                            }}>
                            <img alt=""
                                src={AlertIcon}
                                style={{
                                    height: "12px",
                                    marginLeft: "10px",
                                    marginRight: "10px"
                                }} />

                            <div style={{
                                color: "#EE4139",
                                fontFamily: "Lato",
                                fontWeight: 'bold',
                                fontSize: "9px",
                                marginRight: "10px"
                            }}>
                                #{this.props.id}
                            </div>
                        </div>
                    </div>

                    <div id="bottom-flex-container" style={{
                        display: "flex",
                        flexDirection: "row",
                        gap: 10

                    }}>

                        <div style={{
                            width: "100px",
                            height: "min-content",
                            overflow: "hidden",
                            borderRadius: "25%",
                            objectFit: "cover",
                            marginTop: "20px"

                        }}>

                            <img
                                alt=""
                                src={this.props.icon}
                                style={{
                                    width: " 70%",
                                    height: "100%",
                                }} />
                        </div>

                        <div
                            style={{
                                backgroundColor: "rgba(238, 65, 57, 0.2)",
                                borderRadius: "10px",
                                width: "50%",
                                height: "min-content",
                                marginTop: "15px",
                                paddingLeft: "10px",
                                paddingRight: "10px"
                            }} >

                            <div
                                style={{
                                    color: "rgba(0, 0, 0, 0.75)",
                                    fontSize: "10px",
                                    fontWeight: "normal",
                                    leftMargin: "20px",
                                    whiteSpace: "nowrap",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                    textAlign: "center",
                                    marginTop: "10px"
                                }}>
                                Total Calls
                            </div>

                            <div
                                style={{
                                    color: "#EE4139",
                                    fontFamily: "Lato",
                                    fontWeight: 'bold',
                                    fontSize: "18px",
                                    leftMargin: "20px",
                                    whiteSpace: "nowrap",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                    textAlign: "center",
                                    marginBottom: "10px"
                                }}>
                                {Math.trunc(this.props.totalCalls)}
                            </div>
                        </div>
                        <div
                            style={{

                                backgroundColor: "rgba(238, 65, 57, 0.2)",
                                borderRadius: "10px",
                                width: "50%",
                                height: "min-content",
                                marginTop: "15px",
                                paddingLeft: "10px",
                                paddingRight: "10px",

                            }} >

                            <div
                                style={{
                                    color: "rgba(0, 0, 0, 0.75)",
                                    fontSize: "10px",
                                    leftMargin: "20px",
                                    fontWeight: "normal",
                                    whiteSpace: "nowrap",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                    textAlign: "center",
                                    marginTop: "10px",
                                }}>
                                Calls Per Month
                            </div>

                            <div
                                style={{
                                    color: "#EE4139",
                                    fontFamily: "Lato",
                                    fontWeight: 'bold',
                                    fontSize: "18px",
                                    leftMargin: "20px",
                                    whiteSpace: "nowrap",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                    textAlign: "center",
                                    marginBottom: "10px"

                                }}>
                                {Math.trunc(this.props.monthlyCalls)}
                            </div>
                        </div>
                    </div>
                </div >
            </div >

        );
    }
}

TotalEmergencyCallsCard.propTypes = {
    name: PropTypes.string,
    title: PropTypes.string,
    totalCalls: PropTypes.number,
    monthlyCalls: PropTypes.number,
    id: PropTypes.number,
    icon: PropTypes.string


};

TotalEmergencyCallsCard.defaultProps = {
    name: "NAME",
    title: "Total Calls",
    totalCalls: 420,
    monthlyCalls: 12,
    id: 1,
    icon: MedicalIcon

};
export default TotalEmergencyCallsCard;