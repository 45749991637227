import React from "react";
import ConfirmationDialog from "../dialogs/ConfirmationDialog";
// import EmergencyCallCard from '../widgets/EmergencyCallCard';
// import EmergencyCallsAnalysis from '../widgets/EmergencyCallsAnalysis';
// import EmergencyCallCard from '../widgets/EmergencyCallCard';
// import TotalEmergencyCallsCard from '../widgets/TotalEmergencyCallsCard';
// import RegisteredUsersCount from '../widgets/RegisteredUsersCount';
// import EmergencyCallsAnalysis from '../widgets/EmergencyCallsAnalysis';
// import FeedCard from '../widgets/FeedCard';
// import KH from './KH';
// import CardFrame from '../widgets/CardFrame';
// import EmergencyCardOverview from '../widgets/EmergencyCardOverview';
// import EmergencyIcons from '../widgets/EmergencyIcons'
// import NotificationAlert from '../widgets/NotificationAlert';
// import AddFeed from './AddFeedPage';
import MDEditor, { selectWord } from "@uiw/react-md-editor";
import MarkdownEditor from "@uiw/react-markdown-editor";
import rehypeSanitize from "rehype-sanitize";
class TestPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    return (
      <div>
        <MarkdownEditor
          value={this.state.content}
          onChange={(value, viewUpdate) => {
            console.log(value);
            this.setState({ content: value });
          }}
        />
      </div>
    );
  }
}
export default TestPage;
